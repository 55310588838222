import request from '@/utils/request'

//权限 列表
export function Perlist(data) {
  return request({
    method: 'post',
    url: '/system/permission/list',
    // mockUrl: '/mock/setting/list',
    data
  })
}
// 系统选择
export function SystemList(data) {
  return request({
    method: 'post',
    url: '/system/system/system_options',
    data
  })
}
// 权限详情 
export function PermissionInfo(data) {
  return request({
    method: 'post',
    url: '/system/permission/show',
    data
  })
}
// 创建权限
export function CreatePermission(data) {
  return request({
    method: 'post',
    url: '/system/permission/create',
    data
  })
}
// 修改权限
export function UpdatePermission(data) {
  return request({
    method: 'post',
    url: '/system/permission/update',
    data
  })
}
// 权限选择
export function GetparentOptions(data) {
  return request({
    method: 'post',
    url: '/system/permission/permission_options',
    data
  })
}
//  权限 删除  system/permission/delete
export function DeletePermission(data) {
  return request({
    method: 'post',
    url: '/system/permission/delete',
    data
  })
}


// 角色 列表
export function Getruleslist(data) {
  return request({
    method: 'post',
    url: '/system/role/list',
    data
  })
}
//  角色 详情
export function Getrulesinfo(data) {
  return request({
    method: 'post',
    url: '/system/role/show',
    data
  })
}
//    修改角色 
export function EditRules(data) {
  return request({
    method: 'post',
    url: '/system/role/update',
    data
  })
}
// 创建角色
export function createRules(data) {
  return request({
    method: 'post',
    url: '/system/role/create',
    data
  })
}
// 系统选择接口
export function SystemrolrsList(data) {
  return request({
    method: 'post',
    url: '/system/system/system_options',
    data
  })
}

// 权限选择接口 /role/permission_options
export function Getrolepermission(data) {
  return request({
    method: 'post',
    url: '/system/permission/permission_options',
    data
  })
}

// 公司列表
export function GetCompanylist(data) {
  return request({
    method: 'post',
    url: '/system/company/list',
    data
  })
}
// 公司选择接口
export function CompanyoptionList(data) {
  return request({
    method: 'post',
    url: '/system/company/company_options',
    data
  })
}
// 创建公司 
export function Createcompany(data) {
  return request({
    method: 'post',
    url: '/system/company/create',
    data
  })
}

// 修改公司
export function Updatecompany(data) {
  return request({
    method: 'post',
    url: '/system/company/update',
    data
  })
}
//  公司详情
export function CompanyInfo(data) {
  return request({
    method: 'post',
    url: '/system/company/show',
    data
  })
}

// 员工 列表
export function Getstafflist(data) {
  return request({
    method: 'post',
    url: '/system/staff/list',
    data
  })
}
// 员工 详情
export function Getstaffinfo(data) {
  return request({
    method: 'post',
    url: '/system/staff/show',
    data
  })
}
//员工 添加
export function Addstaff(data) {
  return request({
    method: 'post',
    url: '/system/staff/create',
    data
  })
}
//员工 修改
export function Updatestaff(data) {
  return request({
    method: 'post',
    url: '/system/staff/update',
    data
  })
}
// 员工 公司选择接口  
export function GetCompanyid(data) {
  return request({
    method: 'post',
    url: '/system/company/company_options',
    data
  })
}
// 员工 角色选择接口  
export function GetRoleid(data) {
  return request({
    method: 'post',
    url: '/system/role/role_options',
    data
  })
}

// 短信 模板
export function GetsmsList(data) {
  return request({
    method: 'post',
    url: '/system/sms/list',
    data
  })
}
// 短信 添加
export function CreateSms(data) {
  return request({
    method: 'post',
    url: '/system/sms/create',
    data
  })
}
// 短信 修改
export function UpdateSms(data) {
  return request({
    method: 'post',
    url: '/system/sms/update',
    data
  })
}
// 短信 模版详情
export function GetSmsInfo(data) {
  return request({
    method: 'post',
    url: '/system/sms/show',
    data
  })
}