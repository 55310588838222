// 数据colom
export default {
  goods: {
    columns: [
      {
        title: 'ID',
        dataIndex: 'permission_id',
        key: '_0',
        ellipsis: true,
        width: 80,
        scopedSlots: { customRender: '_0' },
      },
      {
        title: '权限名称',
        dataIndex: 'permission_name',
        key: '_3',
        ellipsis: true,
        scopedSlots: { customRender: '_3' },
      },
      {
        title: '权限图标',
        dataIndex: 'permission_icon',
        key: '_4',
        ellipsis: true,
        scopedSlots: { customRender: '_4' },
      },
      {//todo
        title: '类型',
        dataIndex: 'permission_type',
        key: '_5',
        ellipsis: true,
        customRender: (name, record) => {
          return record.permission_type == 1 ? '菜单' : '权限'
        },
        scopedSlots: { customRender: '_5' },
      },
      {
        title: '权限路由',
        dataIndex: 'permission_route',
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },
      // {
      //   title: '权限对应组件',
      //   dataIndex: 'permission_component',
      //   key: '_6',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_6' },
      // },
      // {
      //   title: '权限参数',
      //   dataIndex: 'permission_params',
      //   key: '_8',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_8' },
      // },
      // {
      //   title: '权限类型',
      //   dataIndex: 'permission_params',
      //   key: '_9',
      //   ellipsis: true,
      //   customRender: (name, record) => {
      //     return record.permission_params == 1 ? '菜单' : '权限'
      //   },
      //   scopedSlots: { customRender: '_9' },
      // },
      {
        title: '权限状态',
        dataIndex: 'permission_is_show',
        key: '_10',
        ellipsis: true,
        scopedSlots: { customRender: '_10' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        // width: '220px',
        scopedSlots: { customRender: '_7' },
      },
    ]
  },
  goodstype: {
    columns1: [
      {
        title: '分类名称',
        dataIndex: 'category_name',
        key: '_2',
        ellipsis: true,
        scopedSlots: { customRender: '_2' },
      },
      // {
      //   title: '关联商品',
      //   dataIndex: 'category_icon',
      //   key: '_3',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_3' },
      // },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        width: 280,
        scopedSlots: { customRender: '_7' },
      },
    ],
    columns2: [
      {
        title: '分类名称',
        dataIndex: 'category_name',
        key: '_2',
        ellipsis: true,
        className: 'flex-center',
        scopedSlots: { customRender: '_2' },
      },
      // {
      //   title: '关联商品',
      //   dataIndex: 'category_icon',
      //   key: '_3',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_3' },
      // },
      {
        title: '显示',
        dataIndex: 'category_status',
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },

      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        width: 280,
        scopedSlots: { customRender: '_7' },
      },
    ],
    columns3: [
      {
        title: '分类名称',
        dataIndex: 'category_name',
        key: '_2',
        ellipsis: true,
        className: 'flex-center',
        scopedSlots: { customRender: '_2' },
      },
      // {
      //   title: '关联商品',
      //   dataIndex: 'category_icon',
      //   key: '_3',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_3' },
      // },
      {
        title: '显示',
        dataIndex: 'category_status',
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },

      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        width: 280,
        scopedSlots: { customRender: '_7' },
      },
    ],
  },
  brand: {
    columns: [
      // {
      //   title: 'ID',
      //   dataIndex: 'brand_id',
      //   key: '_0',
      //   ellipsis: true,
      //   width: 80,
      //   scopedSlots: { customRender: '_0' },
      // },
      {//todo
        title: '品牌名称',
        dataIndex: 'brand_name',
        key: '_1',
        // ellipsis: true,
        // customRender: (name, record) => {
        //   return (record.brand_name || '--')
        // },
        scopedSlots: { customRender: '_1' },
      },
      // {
      //   title: '关联商品',
      //   dataIndex: 'category_name',
      //   key: '_2',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_2' },

      // },
      // {
      //   title: '分类名称',
      //   dataIndex: 'category_name',
      //   key: '_2',
      //   ellipsis: true,
      //   customRender: (name, record) => {
      //     return (record.category_name || '--')
      //   },
      //   scopedSlots: { customRender: '_2' },
      // },
      // {
      //   title: '分类图标',
      //   dataIndex: 'category_icon',
      //   key: '_3',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_3' },
      // },
      // {
      //   title: '图标',
      //   dataIndex: 'brand_icon',
      //   key: '_4',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_4' },
      // },
      // {
      //   title: '排序',
      //   dataIndex: 'brand_sort',
      //   key: '_5',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_5' },
      // },
      {
        slots: { title: 'goodsBrands' },
        // title: '品牌',
        dataIndex: 'brand_type',
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },
      // {
      //   title: '品牌状态',
      //   dataIndex: 'brand_status',
      //   key: '_6',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_6' },
      //   customRender: (name, record) => {
      //     return record.brand_status == 1 ? '展示' : '不展示'
      //   },
      // },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        width: 230,
        scopedSlots: { customRender: '_7' },
      },
    ]
  },
  management: {
    columns: [
      {
        title: '产品名称',
        dataIndex: 'goods_name',
        key: '_1',
        ellipsis: true,
        // customRender: (name, record) => {
        //   return (record.goods_name || '--')
        // },
        scopedSlots: { customRender: '_1' },
        // fixed: 'left'
      },
      {//todo
        title: '商品码',
        dataIndex: 'goods_bn',
        key: '_2',
        ellipsis: true,
        // customRender: (name, record) => {
        //   return (record.goods_bn || '--')
        // },
        scopedSlots: { customRender: '_2' },
        // fixed: 'left'
      },
      {
        // title: '类别',
        slots: { title: 'goodsCategorys' },
        dataIndex: 'goods_type',
        key: '_10',
        ellipsis: true,
        scopedSlots: { customRender: '_10' },
      },
      {
        slots: { title: 'goodstypes' },
        // title: '分类',
        dataIndex: 'goods_category',
        key: '_11',
        ellipsis: true,
        scopedSlots: { customRender: '_11' },
      },
      {
        slots: { title: 'goodsStatus' },
        // title: '状态',
        dataIndex: 'is_show',
        key: '_12',
        ellipsis: true,
        scopedSlots: { customRender: '_12' },

      },
      {
        slots: { title: 'goodsBrands' },
        // title: '品牌',
        dataIndex: 'goods_brand',
        key: '_13',
        ellipsis: true,
        scopedSlots: { customRender: '_13' },
      },

      // {
      //   title: '商品单位',
      //   dataIndex: 'goods_unit',
      //   key: '_14',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_14' },
      // },
      // {
      //   title: '商品库存',
      //   dataIndex: 'goods_stock',
      //   key: '_15',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_15' },
      //   // fixed: 'right'
      // },
      // {
      //   title: '销量',
      //   dataIndex: 'goods_sales',
      //   key: '_16',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_16' },
      //   // fixed: 'right'
      // },

      // {
      //   title: '是否删除',
      //   dataIndex: 'is_del',
      //   key: '_18',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_18' },
      //   customRender: (name, record) => {
      //     return record.is_del == 1 ? '已删除' : "未删除"
      //   },
      // fixed: 'right'
      // },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        width: 250,
        scopedSlots: { customRender: '_7' },
        // fixed: 'right'
      },
    ]
  },

  management_gifts: {
    columns: [
      {
        title: '产品名称',
        dataIndex: 'goods_name',
        key: '_1',
        ellipsis: true,
        customRender: (name, record) => {
          return (record.goods_name || '--')
        },
        scopedSlots: { customRender: '_1' },
      },
      {
        title: '商品码',
        dataIndex: 'goods_bn',
        key: '_2',
        ellipsis: true,
        customRender: (name, record) => {
          return (record.goods_bn || '--')
        },
        scopedSlots: { customRender: '_1' },
        // fixed: 'left'
      },
      // {
      //   title: '赠送数量',
      //   dataIndex: 'goods_desc',
      //   key: '_3',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_3' },
      // },
      // {
      //   title: '最后一次赠送时间',
      //   dataIndex: 'updated_at',
      //   key: '_4',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_4' },
      // },
      // {
      //   title: '核销数量',
      //   dataIndex: 'goods_price',
      //   key: '_5',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_5' },
      // },


      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        width: 268,
        scopedSlots: { customRender: '_7' },
        // fixed: 'right'
      },
    ]
  },
  service: {
    columns: [

      {
        title: '服务名称',
        dataIndex: 'goods_name',
        key: '_1',
        ellipsis: true,
        customRender: (name, record) => {
          return (record.goods_name || '--')
        },
        scopedSlots: { customRender: '_2' },
      },
      {
        title: '商品码',
        dataIndex: 'goods_bn',
        key: '_2',
        ellipsis: true,
        customRender: (name, record) => {
          return (record.goods_bn || '--')
        },
        scopedSlots: { customRender: '_1' },
      },
      {
        slots: { title: 'goodstypes' },
        // title: '分类',
        dataIndex: 'goods_category',
        key: '_11',
        ellipsis: true,
        scopedSlots: { customRender: '_11' },
      },
      {
        slots: { title: 'goodsStatus' },
        // title: '状态',
        dataIndex: 'is_show',
        key: '_12',
        ellipsis: true,
        scopedSlots: { customRender: '_12' },

      },
      {
        slots: { title: 'goodsBrands' },
        // title: '品牌',
        dataIndex: 'goods_brand',
        key: '_13',
        ellipsis: true,
        scopedSlots: { customRender: '_13' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        width: 252,
        scopedSlots: { customRender: '_7' },
        // fixed: 'right'
      },
    ]
  },
  service_gifts: {
    columns: [
      {
        title: '服务名称',
        dataIndex: 'goods_name',
        key: '_1',
        ellipsis: true,
        customRender: (name, record) => {
          return (record.goods_name || '--')
        },
        scopedSlots: { customRender: '_1' },
      },
      {
        title: '商品码',
        dataIndex: 'goods_bn',
        key: '_2',
        ellipsis: true,
        customRender: (name, record) => {
          return (record.goods_bn || '--')
        },
        scopedSlots: { customRender: '_1' },
        // fixed: 'left'
      },
      // {
      //   title: '赠送数量',
      //   dataIndex: 'goods_desc',
      //   key: '_3',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_3' },
      // },
      // {
      //   title: '最后一次赠送时间',
      //   dataIndex: 'updated_at',
      //   key: '_4',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_4' },
      // },
      // {
      //   title: '核销数量',
      //   dataIndex: 'goods_price',
      //   key: '_5',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_5' },
      // },
      // {
      //   title: '最后一次核销时间',
      //   dataIndex: 'goods_price2',
      //   key: '_6',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_6' },
      // },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        width: 268,
        scopedSlots: { customRender: '_7' },
        // fixed: 'right'
      },
    ]
  },
  plan: {
    columns: [
      {
        title: '卡项名称',
        dataIndex: 'goods_name',
        key: '_1',
        ellipsis: true,
        // customRender: (name, record) => {
        //   return (record.goods_name || '--')
        // },
        scopedSlots: { customRender: '_1' },
        // fixed: 'left'
      },
      {//todo
        title: '商品码',
        dataIndex: 'goods_bn',
        key: '_2',
        ellipsis: true,
        // customRender: (name, record) => {
        //   return (record.goods_bn || '--')
        // },
        scopedSlots: { customRender: '_2' },
        // fixed: 'left'
      },
      // {
      //   slots: { title: 'activeways' },
      //   // title: '激活方式',
      //   dataIndex: 'activeways',
      //   key: '_5',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_5' },
      // },
      {
        slots: { title: 'goodstypes' },
        // title: '分类',
        dataIndex: 'goods_category',
        key: '_11',
        ellipsis: true,
        scopedSlots: { customRender: '_11' },
      },
      {
        slots: { title: 'goodsStatus' },
        // title: '状态',
        dataIndex: 'is_show',
        key: '_12',
        ellipsis: true,
        scopedSlots: { customRender: '_12' },
        // customRender: (name, record) => {
        //   return record.is_show == 0 ? '下架' : "上架"
        // },
      },
      {
        slots: { title: 'goodsBrands' },
        // title: '品牌',
        dataIndex: 'goods_brand',
        key: '_13',
        ellipsis: true,
        scopedSlots: { customRender: '_13' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        width: 302,
        scopedSlots: { customRender: '_7' },
        // fixed: 'right'
      },
    ]
  },
  plan_gifts: {
    columns: [
      {
        title: '卡项名称',
        dataIndex: 'goods_name',
        key: '_1',
        ellipsis: true,
        customRender: (name, record) => {
          return (record.goods_name || '--')
        },
        scopedSlots: { customRender: '_1' },
      },
      {
        title: '商品码',
        dataIndex: 'goods_bn',
        key: '_2',
        ellipsis: true,
        customRender: (name, record) => {
          return (record.goods_bn || '--')
        },
        scopedSlots: { customRender: '_1' },
        // fixed: 'left'
      },
      // {
      //   title: '赠送数量',
      //   dataIndex: 'goods_desc',
      //   key: '_3',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_3' },
      // },
      // {
      //   title: '最后一次赠送时间',
      //   dataIndex: 'updated_at',
      //   key: '_4',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_4' },
      // },
      // {
      //   title: '核销数量',
      //   dataIndex: 'goods_price',
      //   key: '_5',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_5' },
      // },


      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        width: 268,
        scopedSlots: { customRender: '_7' },
        // fixed: 'right'
      },
    ]
  },
  plan_add: {
    columns: [
      {
        title: '组合',
        dataIndex: 'isGroup',
        key: '_0',
        scopedSlots: { customRender: '_0' },
        width: 22,
        customRender: (text, row, index) => {
          const obj = {
            children: this.$createElement(
              'span',
              {
                class: 'guigeTableSpec_th0',
              }
            ),
            // children: (<a href="javascript:;">{ row.rowSpan > 1 ? '组合' : ''}</a>),
            // children: row.rowSpan > 1 ? '组合' : '非组合',
            attrs: {}
          }
          obj.attrs.rowSpan = row.rowSpan
          return obj
        },
      },
      {
        title: '服务名称',
        dataIndex: 'goods_name',
        key: '_1',
        ellipsis: true,
        scopedSlots: { customRender: '_1' },

      },
      {
        title: '商品码',
        dataIndex: 'goods_bn',
        key: '_2',
        ellipsis: true,
        scopedSlots: { customRender: '_2' },
        // fixed: 'left'
      },
      // {
      //   title: '赠送数量',
      //   dataIndex: 'goods_desc',
      //   key: '_3',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_3' },
      // },
      // {
      //   title: '最后一次赠送时间',
      //   dataIndex: 'updated_at',
      //   key: '_4',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_4' },
      // },
      // {
      //   title: '核销数量',
      //   dataIndex: 'goods_price',
      //   key: '_5',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_5' },
      // },
      {
        title: '状态',
        dataIndex: 'goods_type',
        key: '_10',
        ellipsis: true,
        scopedSlots: { customRender: '_10' },
      },

      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        width: 268,
        scopedSlots: { customRender: '_7' },
        // fixed: 'right'
      },
    ]
  },
  storedvaluecard: {
    columns: [
      {
        title: '储值卡名称',
        dataIndex: 'goods_name',
        key: '_1',
        ellipsis: true,
        // customRender: (name, record) => {
        //   return (record.goods_name || '--')
        // },
        scopedSlots: { customRender: '_2' },
      },
      {
        title: '商品码',
        dataIndex: 'goods_bn',
        key: '_2',
        ellipsis: true,
        // customRender: (name, record) => {
        //   return (record.goods_bn || '--')
        // },
        scopedSlots: { customRender: '_1' },
      },
      {
        slots: { title: 'goodstypes' },
        // title: '分类',
        dataIndex: 'goods_category',
        key: '_11',
        ellipsis: true,
        scopedSlots: { customRender: '_11' },
      },
      {
        slots: { title: 'goodsStatus' },
        // title: '状态',
        dataIndex: 'is_show',
        key: '_12',
        ellipsis: true,
        scopedSlots: { customRender: '_12' },

      },
      {
        slots: { title: 'goodsBrands' },
        // title: '品牌',
        dataIndex: 'goods_brand',
        key: '_13',
        ellipsis: true,
        scopedSlots: { customRender: '_13' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        width: 256,
        scopedSlots: { customRender: '_7' },
        // fixed: 'right'
      },
    ]
  }
}